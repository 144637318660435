import { Login, LoginSuccess, Register } from "../components/auth";
import { CLIENT_ROUTE } from "./ClientRoutes";
import { ItemPage } from "../components/item/Page";
import { Home } from "../components/home2";
import { UserProfile } from "../components/user/Profile";
import { PageNotFound } from "../components/error";

export const ROUTES = [
  { path: CLIENT_ROUTE.HOME, component: Home },
  { path: CLIENT_ROUTE.ITEM(':productId'), component: ItemPage },
  { path: CLIENT_ROUTE.USER(':userId'), component: UserProfile, guarded: true },
  { path: CLIENT_ROUTE.LOGIN, component: Login, guarded: false },
  { path: CLIENT_ROUTE.REGISTER, component: Register, guarded: false },
  { path: CLIENT_ROUTE.LOGIN_SUCCESS, component: LoginSuccess, guarded: false },
  { path: CLIENT_ROUTE.LOGIN_GOOGLE, component: () => null },
  { path: CLIENT_ROUTE.ABOUT, component: Home },
  { path: CLIENT_ROUTE.CONTACT, component: Home },
  { path: "*", component: PageNotFound },
];
  // { path: "*", component: (() => <Navigate to={CLIENT_ROUTE.HOME} />) },
