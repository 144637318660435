import React from "react";
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { truncateText } from "../../lib/utils";

const TruncateTitle = ({ title, maxLength = 65 }) => {
  const isTruncated = title.length >= maxLength;

  return (
    <Tooltip title={isTruncated ? title : ''} arrow>
      <span>{truncateText(title, maxLength)}</span>
    </Tooltip>
  );
};

TruncateTitle.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  maxLength: PropTypes.number,
};

export default TruncateTitle;
