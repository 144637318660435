// Routes.js
import React, { useEffect, useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { ROUTES } from "./constants/Routes";
import AuthContext from "./context/authContext";
import ProtectedRoute from "./components/route/ProtectedRoute";

const AppRoutes = () => {
  const auth = useContext(AuthContext);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    setAuthChecked(true);
  }, [auth.isLoggedIn]);

  if (!authChecked) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      {ROUTES.map(({ path, component: Component, guarded = null }) => (
        guarded && guarded === true ? (
          <Route
            key={path}
            path={path}
            element={(
              <ProtectedRoute key={path} path={path} isLoggedIn={auth.isLoggedIn}>
                <Component />
              </ProtectedRoute>
            )}
          />
        ) : (
          <Route key={path} path={path} element={<Component />} />
        )
      ))}
    </Routes>
  );
};

export default AppRoutes;
