const APP_NAME = process.env.REACT_APP_NAME;

const DOMAIN_NAME = process.env.REACT_APP_URL || "http://localhost:3000";

const SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:8000";

const DEAL_LIMIT = 3;

const QUICK_TIPS = [
  {
    title: ".99",
    description: "Prices ending in .99 are full price",
  },
  {
    title: ".97",
    description:
      "Store manager deals, These items wont be around for long and are offered at an amazing discount",
  },
  {
    title: ".49 & .79",
    description:
      "Manufacture special offers. These prices are usually applied to products that have a trail run and are usually cheaper than their retail price",
  },
  // cheaper: {
  //   title: ".79",
  //   description: ""
  // },
  {
    title: ".00",
    description:
      "The manager whats these items off the shelf for good. That means they're priced down and nearly out of stock",
  },
  {
    title: "*",
    description:
      "Discontinued items. Usually, these are priced cheaply to get them off the shelves",
  },
];

const DEAL_TYPE_NAME = {
  webOnly: "Online only deals",
  inStoreOnly: "In store only deals",
  favorites: "Your Favorite Items",
  notifications: "Your Notification Items",
};

export {
  APP_NAME,
  DOMAIN_NAME,
  SERVER_URL,
  DEAL_LIMIT,
  QUICK_TIPS,
  DEAL_TYPE_NAME,
};
