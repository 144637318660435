import * as React from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

const ItemPrice = ({ product, big = false }) => {
  const { prevPrice, dealAmount, price } = product;
  const size = big ? 200 : 150;

  const generateRow = (label, value) => (
    <TableRow key={label}>
      <TableCell sx={{
        alignItems: 'flex-start', borderBottom: "none", width: '10%', padding: '0',
      }}
      >
        {label === 'Price' && <Divider />}
        <Typography align="right" color="inherit" variant={big ? 'h6' : 'body1'}>
          {`${label}:`}
        </Typography>
      </TableCell>
      <TableCell sx={{ borderBottom: "none", padding: '0' }}>
        {label === 'Price' && <Divider />}
        <Typography align="right" color="inherit" variant={big ? 'h6' : 'body1'}>
          {`${label === 'Discount' ? '-' : ''}$${value}`}
        </Typography>
      </TableCell>
    </TableRow>
  );

  return (
    <Table mb={3} style={{ width: size }}>
      <TableBody>
        {dealAmount && generateRow('Original', prevPrice)}
        {dealAmount && generateRow('Discount', dealAmount)}
        {generateRow('Price', price)}
      </TableBody>
    </Table>
  );
};

ItemPrice.propTypes = {
  product: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  big: PropTypes.bool,
};

export default ItemPrice;
