// App.js
import './App.css';
import React from "react";
import { Grid } from '@mui/material';
import { Nav } from "./components/nav";
import { Footer } from "./components/footer";
import AuthContext from "./context/authContext";
import useAuth from "./hooks/useAuth";
import AppRoutes from "./Routes";

const App = () => {
  const auth = useAuth();

  return (
    <div className="App">
      <AuthContext.Provider value={auth}>
        <Nav />
        <Grid mt={3} container justifyContent="center">
          <Grid item xs={10}>
            <AppRoutes />
          </Grid>
        </Grid>
        <Footer />
      </AuthContext.Provider>
    </div>
  );
};

export default App;
