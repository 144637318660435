// useAuth.js
import {
  useState, useCallback, useEffect, useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { CLIENT_ROUTE } from '../constants/ClientRoutes';

const useAuth = () => {
  const [userData, setUserData] = useState(localStorage.getItem("userSession"));
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(!!userData);
  const navigate = useNavigate();

  const login = useCallback((user) => {
    const expireDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 12);
    setUserData(user);
    setTokenExpirationDate(expireDate);

    localStorage.setItem("userSession", JSON.stringify({ user, expiration: expireDate.toISOString() }));
    navigate(CLIENT_ROUTE.HOME);
  }, []);

  const logout = useCallback(async () => {
    try {
      localStorage.removeItem("userSession");
      setUserData(null);
      setTokenExpirationDate(null);

      navigate(CLIENT_ROUTE.HOME);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }, []);

  useEffect(() => {
    if (tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      const logoutTimer = setTimeout(logout, remainingTime);
      return () => clearTimeout(logoutTimer);
    }
    return () => {};
  }, [logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userSession"));
    if (storedData && new Date(storedData.expiration) > new Date()) {
      setUserData(storedData.user);
      login(storedData.user);
    }
  }, [login]);

  useEffect(() => {
    setIsLoggedIn(!!userData);
  }, [userData]);

  return useMemo(() => ({
    isLoggedIn,
    user: userData,
    login,
    logout,
  }), [isLoggedIn, userData, login, logout]);
};

export default useAuth;
