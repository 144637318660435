import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { styled } from "@mui/material/styles";

const getChipStyles = (priceRank) => {
  switch (priceRank) {
    case 'Best Price':
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    case 'Great Price':
      return {
        backgroundColor: 'lightgreen',
        color: 'black',
      };
    case 'Average':
      return {
        borderColor: 'green',
        borderWidth: 2,
        borderStyle: 'solid',
        backgroundColor: 'white',
        color: 'green',
      };
    case 'Poor Price':
      return {
        backgroundColor: 'lightcoral',
        color: 'black',
      };
    case 'Worst Price':
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    default:
      return {};
  }
};

const CustomChip = styled(Chip)(({ rank }) => ({
  ...getChipStyles(rank),
}));

const PriceRankChip = ({ priceRank }) => <CustomChip label={priceRank} rank={priceRank} />;

PriceRankChip.propTypes = {
  priceRank: PropTypes.string.isRequired,
};

export default PriceRankChip;
