import React from "react";
import PropTypes from 'prop-types';
import { Navigate } from "react-router-dom";
import { CLIENT_ROUTE } from "../../constants/ClientRoutes";

const ProtectedRoute = ({ isLoggedIn, children }) => {
  if (!isLoggedIn) {
    return <Navigate to={CLIENT_ROUTE.HOME} replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
