import React from "react";
import { Typography } from "@mui/material";

const PageNotFound = () => (
  <Typography variant="h1" component="h2">
    Error 404: Page not found
  </Typography>

);

export default PageNotFound;
