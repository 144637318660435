import { api } from './Config';
import { SERVER_ROUTE } from '../constants/ServerRoutes';

export const loginUser = async (credentials) => api.post(SERVER_ROUTE.AUTH.LOGIN, credentials)
  .then((response) => response.data);

export const getGoogleUser = async () => api.get(SERVER_ROUTE.AUTH.USER, { withCredentials: true })
  .then((response) => response.data);

export const registerUser = async (userData) => api.post(SERVER_ROUTE.AUTH.REGISTER, userData);

export const logout = async () => api.get(SERVER_ROUTE.AUTH.LOGOUT);
