import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { fetchItem } from "../../api/Axios";
import { CLIENT_ROUTE } from "../../constants/ClientRoutes";
import "./ItemSearch.css";

const ItemSearch = () => {
  const navigate = useNavigate();
  const [productURL, setProductURL] = useState();
  const [loading, setLoading] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const item = await fetchItem(null, productURL);
      setLoading(false);
      navigate(CLIENT_ROUTE.ITEM(item.id), { state: { itemObject: item } });
    } catch (err) {
      console.log(`Error: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSearch}>
      <div className="search-container">
        <div className="search-icon-wrapper">
          <SearchIcon />
          {loading && <CircularProgress size={24} className="loading-spinner" />}
        </div>
        <InputBase
          sx={{ width: "35rem" }}
          className="styled-input-base"
          placeholder="Find Costco Product..."
          onChange={(e) => setProductURL(e.target.value)}
          inputProps={{ "aria-label": "search" }}
          disabled={loading}
        />
      </div>
    </form>
  );
};

export default ItemSearch;
