import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TimeToBuy = ({ priceRank }) => {
  let backgroundColor;
  let borderColor;
  let message;

  switch (priceRank) {
    case 'Best Price':
    case 'Great Price':
      backgroundColor = 'lightgreen';
      borderColor = 'green';
      message = 'Now is a good time to buy';
      break;
    case 'Average':
      backgroundColor = 'transparent';
      borderColor = 'green';
      message = 'Consider Waiting for a deal';
      break;
    case 'Poor Price':
    case 'Worst Price':
      backgroundColor = 'lightcoral';
      borderColor = 'red';
      message = 'Avoid buying now';
      break;
    default:
      backgroundColor = 'transparent';
      borderColor = 'gray';
      message = 'No price information';
      break;
  }

  return (
    <Box
      sx={{
        width: '80px',
        height: '80px',
        fontSize: '10',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor,
        border: `2px solid ${borderColor}`,
        borderRadius: '4px',
        padding: '10px',
      }}
    >
      <Typography align="center" color="inherit">
        {message}
      </Typography>
    </Box>
  );
};

TimeToBuy.propTypes = {
  priceRank: PropTypes.oneOf(['Best Price', 'Great PRice', 'Average', 'Poor Price', 'Worst Price']).isRequired,
};

export default TimeToBuy;
