export const SERVER_ROUTE = {
  AUTH: {
    LOGIN: "/auth/login",
    LOGIN_GOOGLE: "/auth/google",
    LOGIN_SUCCESS: "/auth/success",
    REGISTER: "/auth/register",
    LOGOUT: "/auth/logout",
    USER: "/auth/user",
  },
  API: {
    ITEM: "/api/item",
    DEALS: "/api/deals",
    FAVORITE: {
      CREATE: "/api/createFavorite",
      DELETE: "/api/deleteFavorite",
    },
    NOTIFICATION: {
      CREATE: "/api/createNotification",
      UPDATE: "/api/updateNotification",
      DELETE: "/api/deleteNotification",
    },
    USER: {
      FAVORITES: (userId) => `/api/user/${userId}/favorite/ids`,
      FAVORITE_ITEMS: (userId) => `/api/user/${userId}/favorite/items`,
      NOTIFICATIONS: (userId) => `/api/user/${userId}/notification/ids`,
      NOTIFICATION_ITEMS: (userId) => `/api/user/${userId}/notification/items`,
      USER: (userId) => `/api/user/${userId}`,
    },
  },
};
