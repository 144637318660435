export const CLIENT_ROUTE = {
  HOME: "/",
  ITEM: (itemId) => `/product/${itemId}`,
  USER: (userId) => `/user/${userId}`,
  LOGIN: "/login",
  LOGIN_GOOGLE: "/auth/google",
  LOGIN_SUCCESS: "/auth/login/success",
  REGISTER: "/user/register",
  LOGOUT: "/logout",
  ABOUT: "/",
  CONTACT: "/",
};
